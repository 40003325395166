
export const results = [
    {
        result: "Ganit",
        title: "גנית",
        text: "אתם טיפוס של בוקר ואוהבים להתחיל את היום שלכם מוקדם, עושים הליכות אטיות ונהנים מהנוף, גיטריסטים בנשמה, אוהבים משחקי קופסה, ואתם שמחים מימים גשומים כי זה אומר שזו האווירה המושלמת להישאר בבית ולקרוא ספר :) אתם מתים על כובע טמבל כי זה הכי ישראלי שיש, ואתם מאוד פוד-אוריינטד ומחכים לארוחת הצהריים בול ב-12 כל יום מחדש! פשוט לא נמאס לכם מאוכל אף פעם..",
        pic: "ganit.jpg"
    },
    {
        result: "Omri",
        title: "עמרי",
        text: "אתם ערניים וחושבים יותר טוב בלילה, אוהבים ללכת מהר ומעדיפים לנגן או לשמוע פסנתר. אוהבים חד משמעית משחקי מחשב וסוני, ומעדיפים לנצל כל זמן פנוי כדי לשמוע ספר מתח שתפס אתכם חזק ;) ביום יום אתם חכמים בשמש וכנראה נראה אתכם חובשים כובע מצחיה בחוץ, ובצהרי היום אתם תעדיפו לחתוך סלט. בעצם, בכל ארוחה תעדיפו סלט.",
        pic: "omri.jpg"
    },
    {
        result: "Tie",
        title: "תיקו",
        text: "אתם חדים לאורך כל היום, אוהבים מוזיקה קצבית ובועטת ובזמנכם הפנוי אתם מעדיפים לטייל או לראות סרט :) אתם אוהבים אוכל בית, וכנראה אתם לא מהנשרפים בשמש ולכן אין לכם העדפות בסוגי הכובעים האפשריים...",
        pic: "both.jpg"
    }
];