import { useState } from "react";

const getAnswerClassNames = (answer, index, chosen) => {
  let className = "queryAnswer";
  if (chosen !== -1) {
    className += " queryAnswerChosen";
    if (chosen === index) {
      className += answer.correct
        ? " queryAnswerCorrect"
        : " queryAnswerIncorrect";
    } else {
      if (answer.correct) {
        className += " queryAnswerCorrectNotChosen";
      }
    }
  }
  return className;
};

const QueryComponent = ({ query, onAnswerClick }) => {
  const [chosen, setChosen] = useState(-1);

  const onClick = (index, isCorrect) => {
    if (chosen === -1) {
      setChosen(index);
      onAnswerClick(isCorrect);
    }
  };

  return (
    <div className="query">
      <div className="queryValue">{query.question}</div>
      <div className="queryAnswers">
        {query.answers.map((answer, index) => (
          <div
            key={index}
            className={getAnswerClassNames(answer, index, chosen)}
            onClick={() => onClick(index, answer.correct)}
          >
            {answer.value}
          </div>
        ))}
      </div>
    </div>
  );
};
export default QueryComponent;
