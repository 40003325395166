export const MainPage = ({familySide, setFamilySide, name, setName, onStartQuestions}) => {
    return (
        <div className="main-page" style={{backgroundImage: "url('/images/quiz-background.jpg')"}}>
            <div>
                {/* <label>
                    מאיזה צד אתה:
                    <select
                        value={familySide}
                        onChange={e => setFamilySide(e.target.value)}
                    >
                        <option value="groom">חתן</option>
                        <option value="bride">כלה</option>
                        <option value="wedding crasher">הסתננתי לאירוע</option>
                    </select>
                </label>
            </div>
            <div>
                <label>
                    מה שמך
                    <input
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </label> */}
            </div>
            <button className="start-button" onClick={onStartQuestions} disabled={!name || !familySide}>
                התחל שאלון
            </button>
        </div>);
}