const Question = ({question, onAnswerSubmit}) => {
    const answersJsx = question.answers.map(answer =>
        (<div className="answer" onClick={() => onAnswerSubmit(answer.result)}>
            <div >{answer.value}</div>
        </div>)
    )
    answersJsx.sort(() => Math.random() - 0.5);

    return (
        <div className="answers-wrapper">
            <div className="q-title">
                {question.value}
            </div>
            <div className="q-image">
                <img src={`/images/q${question.index}.jpg`} alt=""/>
            </div>
            <div className="answers">
                {answersJsx}
            </div>
        </div>
    );
}
export default Question;