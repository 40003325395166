import { Link } from "react-router-dom";

const Games = () => {
  return (
    <div className="gamesScreen" style={{backgroundImage: "url('/images/games-background.jpg')"}}>
      <div className="gamesLinks">
        <Link to="/quiz" className="link">
          ?למי אתם יותר דומים
        </Link>
        <Link to="/questionaire" className="link">
          ?כמה אתם מכירים את הזוג
        </Link>
      </div>
    </div>
  );
};

export default Games;
