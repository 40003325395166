import { Link } from "react-router-dom";

export const ResultScreen = ({ result, onRestart }) => {
  const resultPicPath = "../../images/" + result.pic;
  return (
    <div className="resultScreen">
      <h1 className="resultHeadline">אתם יותר דומים ל...</h1>
      <img className="finalImg" src={resultPicPath} alt="" />
      <h1 className="resultHeadline">{result.title}</h1>
      <div className="resultText">{result.text}</div>
      <div className="resultScreenButtons">
        <button className="resultScreenButton" onClick={onRestart}>
          בא לי לשחק שוב
        </button>
        <Link className="resultScreenButton" to={"/"}>
          חזרה למסך הראשי
        </Link>
      </div>
    </div>
  );
};
