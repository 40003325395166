import {useEffect, useState} from "react";
import {questions} from "../quiz/QuizQuestions";
import Question from "../quiz/QuizQuestion";
import {results} from "../quiz/QuizResults";
import {ResultScreen} from "../quiz/QuizResultScreen";
import {MainPage} from "../quiz/QuizMainPage";

const imagesPaths = ["/images/q1.jpg", "/images/q2.jpg", "/images/q3.jpg", "/images/q4.jpg", 
                     "/images/q5.jpg", "/images/q6.jpg", "/images/q7.jpg", "/images/q8.jpg",
                     "/images/both.jpg", "/images/ganit.jpg", "/images/omri.jpg"];

function Quiz() {
    const [familySide, setFamilySide] = useState("groom");
    const [name, setName] = useState("אנונימי");
    const initialSum = {Omri: 0, Ganit: 0};
    const [sum, setSum] = useState(initialSum);
    const [questionNumber, setQuestionNumber] = useState(null);

    useEffect(() => {
        for (const image of imagesPaths) {
            const imageElement = new Image();
            imageElement.src = image;
          }
    }, [])


    const onStartQuestions = () => setQuestionNumber(0);

    const onAnswerSubmit = (chosenAnswer) => {
        if (chosenAnswer === "Omri") {
            setSum({...sum, Omri: sum.Omri + 1});
        } else if (chosenAnswer === "Ganit") {
            setSum({...sum, Ganit: sum.Ganit + 1});
        }
        setQuestionNumber(questionNumber + 1);
    }

    const onRestart = () => {
        setQuestionNumber(null);
        setSum(initialSum)
    }

    // useEffect when we reach the final screen send data to DB

    return (
        <div className="Quiz" dir="rtl" style={{backgroundImage: "url('/images/background.jpg')"}}>
            {questionNumber === null ? (
                    <MainPage
                        name={name}
                        setName={setName}
                        familySide={familySide}
                        setFamilySide={setFamilySide}
                        onStartQuestions={onStartQuestions}
                    />
                ) :
                questionNumber < questions.length ? (
                        <Question question={questions[questionNumber]} onAnswerSubmit={onAnswerSubmit}/>
                    ) :
                    (
                        <ResultScreen result={getResultBySum(sum)} onRestart={onRestart}/>
                    )}
        </div>
    )
}

function getResultBySum(sum) {
    if (sum.Omri === sum.Ganit) {
        return results.find(result => result.result === "Tie")
    } else if (sum.Omri > sum.Ganit) {
        return results.find(result => result.result === "Omri")
    } else {
        return results.find(result => result.result === "Ganit")
    }
}


export default Quiz;
