import { Link } from "react-router-dom";
import { useEffect } from "react";

const imagesPaths = [
  "/images/background.jpg",
  "/images/games-background.jpg",
  "/images/Drinks.jpg",
  "/images/menu.jpg",
  "/images/quiz-background.jpg",
];

function Home() {
  useEffect(() => {
    for (const image of imagesPaths) {
      const imageElement = new Image();
      imageElement.src = image;
    }
  }, []);

  return (
    <div
      className="home"
      style={{ backgroundImage: "url('/images/welcome.jpg')" }}
    >
      <div className="links">
        <Link to="/games" className="link">
          ?רוצים לשחק
        </Link>
        <Link to="/menu" className="link">
          ?רעבים
        </Link>
        <Link to="/drinks" className="link">
          ?מה עם איזה משקה
        </Link>
        <a
          target="_blank noreferrer"
          href="https://photos.app.goo.gl/Vj6HqEA6rtocgnKZ8"
          className="link"
        >
          ?צילמתם ובא לכם לשתף
        </a>
        <a
          target="_blank noreferrer"
          href="https://payboxapp.page.link/18E5WASv89RR8iNq5"
          className="link"
        >
          קישור לפייבוקס
        </a>
      </div>
    </div>
  );
}

export default Home;
