import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Menu from './pages/Menu';
import Quiz from './pages/Quiz';
import Drinks from './pages/Drinks';
import Questionaire from './pages/Questionaire';
import Games from './pages/Games';


const App = () => {
 return (
    <>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/games" element={<Games />} />
          <Route path="/questionaire" element={<Questionaire />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/drinks" element={<Drinks />} />
       </Routes>
    </>
 );
};

export default App;
