export const questions = [
    {
        index: 1,
        value: "האם אתם טיפוס של בוקר או ערב?",
        answers: [
            {
                value: "2:00 בלילה אפשר להתחיל לסגור את היום",
                result: "Omri",
            },
            {
                value: "08:30 אני כבר באימון",
                result: "Ganit",
            },
            {
                value: "לא משנה לי",
                result: "both",
            }
        ]
    },
    {
        index: 2,
        value: "איזה טעם מסטיק אתם מעדיפים?",
        answers: [
            {
                value: "מנטה",
                result: "Omri",
            },
            {
                value: "פירות",
                result: "Ganit",
            },
            {
                value: "מי לועס מסטיק, כואבת לי הלסת רק מלחשוב על זה",
                result: "both",
            }
        ]
    },
    {
        index: 3,
        value: "באיזה קצב אתם הולכים?",
        answers: [
            {
                value: "מהר מאוד",
                result: "Omri",
            },
            {
                value: "לאט, יש לי רגליים קצרות",
                result: "Ganit",
            },
            {
                value: "מה זאת השאלה המוזרה הזאת",
                result: "both",
            }
        ]
    },
    {
        index: 4,
        value: "מה כלי הנגינה שיותר מדבר אליכם?",
        answers: [
            {
                value: "רק פסנתר, לא פחות ולא יותר",
                result: "Omri",
            },
            {
                value: "גיטרה זה החיים",
                result: "Ganit",
            },
            {
                value: "תופים",
                result: "both",
            }
        ]
    },
    {
        index: 5,
        value: "בשעות הפנאי, אתם מעדיפים:",
        answers: [
            {
                value: "משחקי מחשב",
                result: "Omri",
            },
            {
                value: "משחקי קופסה",
                result: "Ganit",
            },
            {
                value: "לצאת לטיול",
                result: "both",
            }
        ]
    },
    {
        index: 6,
        value: "קיבלתם המלצה על ספר, אתם:",
        answers: [
            {
                value: "שומעים את ה-audiobook בכל הזדמנות",
                result: "Omri",
            },
            {
                value: "קוראים עותק קשיח במהלך הסופש לאור נר וגשם על החלון",
                result: "Ganit",
            },
            {
                value: "מחכים לסרט, קלללל",
                result: "both",
            }
        ]
    },
    {
        index: 7,
        value: "איזה כובע אתם חובשים?",
        answers: [
            {
                value: "כובע מצחייה",
                result: "Omri",
            },
            {
                value: "כובע טמבל",
                result: "Ganit",
            },
            {
                value: "לכובע שלי שלוש פינות...",
                result: "both",
            }
        ]
    },
    {
        index: 8,
        value: "12 בצהריים ואתם במשרד, אתם:",
        answers: [
            {
                value: "הולכים לחתוך סלט כמה שיותר קצוץ",
                result: "Omri",
            },
            {
                value: "מתלוננים למה אף אחד לא מוכן ליציאה לאכול",
                result: "Ganit",
            },
            {
                value: "זמן מעולה לחמם את האוכל שהבאתם מהבית",
                result: "both",
            }
        ]
    },
]
