export const queries = [
    {
        question: "?באיזה שנה גנית ועמרי התחילו לצאת",
        answers: [
            {
                value: "2014",
                correct: true,
            },
            {
                value: "2017",
                correct: false,
            },
            {
                value: "2020",
                correct: false,
            }
        ]
    },
	{
        question: "?איפה הייתה הצעת הנישואים של גנית ועמרי",
        answers: [
            {
                value: "רמת הגולן",
                correct: false,
            },
            {
                value: "הרי ירושלים",
                correct: true,
            },
            {
                value: "רומא",
                correct: false,
            }
        ]
    },
	{
        question: "?באיזו עיר בחוץ לארץ גנית ועמרי ביקרו הכי הרבה פעמים",
        answers: [
            {
                value: "ליסבון",
                correct: false,
            },
            {
                value: "בודפשט",
                correct: false,
            },
            {
                value: "רומא",
                correct: true,
            }
        ]
    },
	{
        question: "?מי דובר יותר שפות",
        answers: [
            {
                value: "גנית",
                correct: true,
            },
            {
                value: "עמרי",
                correct: false,
            },
            {
                value: "הם מדברים את אותה כמות שפות",
                correct: false,
            }
        ]
    },
	{
        question: "?היכן גנית ועמרי נפגשו לראשונה",
        answers: [
            {
                value: "בשירות הצבאי",
                correct: true,
            },
            {
                value: "בטיול אחרי צבא",
                correct: false,
            },
            {
                value: "במסיבה",
                correct: false,
            }
        ]
    },
	{
        question: "?באיזו עיר גנית ועמרי גרים היום",
        answers: [
            {
                value: "הרצליה",
                correct: false,
            },
            {
                value: "פתח תקווה",
                correct: true,
            },
            {
                value: "קרית אתא",
                correct: false,
            }
        ]
    },
	{
        question: "?מי הכי מצחיקה",
        answers: [
            {
                value: "גנית",
                correct: true,
            },
            {
                value: "גנית",
                correct: true,
            },
            {
                value: "היא הכריחה אותי לכתוב את השאלה הזו",
                correct: true,
            }
        ]
    },
    {
        question: "?מה קרה לזוג בדייט הראשון שלהם",
        answers: [
            {
                value: "היה להם פנצ'ר בדרך חזרה והם נאלצו להחליף גלגל",
                correct: false,
            },
            {
                value: "הם אכלו אוכל לא טוב וחטפו קלקול קיבה",
                correct: false,
            },
            {
                value: "הם היו בחוף בלילה וגנבו להם את שני הפלאפונים",
                correct: true,
            }
        ]
    },
	{
        question: "?מי נוהג מהר יותר",
        answers: [
            {
                value: "עמרי",
                correct: false,
            },
            {
                value: "גנית",
                correct: true,
            },
        ]
    },
	{
        question: "?מי נולד קודם",
        answers: [
            {
                value: "עמרי",
                correct: true,
            },
            {
                value: "גנית",
                correct: false,
            },
        ]
    },
    {
        question: "?מי בממוצע מתקלח יותר זמן",
        answers: [
            {
                value: "עמרי",
                correct: true,
            },
            {
                value: "גנית",
                correct: false,
            },
        ]
    },
	{
        question: "?מי יותר פדחן מבין השניים",
        answers: [
            {
                value: "עמרי",
                correct: false,
            },
            {
                value: "גנית",
                correct: true,
            },
        ]
    },
	{
        question: "?מי מביניהם האח/ות הצעיר/ה ביותר במשפחה",
        answers: [
            {
                value: "עמרי",
                correct: false,
            },
            {
                value: "גנית",
                correct: true,
            },
        ]
    },
    {
        question: "?מי יותר אוהב הפתעות",
        answers: [
            {
                value: "עמרי",
                correct: false,
            },
            {
                value: "גנית",
                correct: true,
            },
        ]
    },
	{
        question: "?מי יארוז יותר מדי ציוד לטיול",
        answers: [
            {
                value: "עמרי",
                correct: true,
            },
            {
                value: "גנית",
                correct: false,
            },
        ]
    },
	{
        question: "?למי יש יותר סיכוי לשבור מרפק בהחלקה על הקרח",
        answers: [
            {
                value: "עמרי",
                correct: false,
            },
            {
                value: "גנית",
                correct: true,
            },
        ]
    },
	{
        question: "?מי בדרך כלל מתנצל ראשון אחרי ריב",
        answers: [
            {
                value: "עמרי",
                correct: true,
            },
            {
                value: "גנית",
                correct: false,
            },
        ]
    },
	{
        question: "?מי מפציץ את השני בסרטונים חמודים ומצחיקים",
        answers: [
            {
                value: "עמרי",
                correct: false,
            },
            {
                value: "גנית",
                correct: true,
            },
        ]
    },
];