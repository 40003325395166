import { useState, useRef, useEffect } from "react";
import { queries } from "../questionaire/Queries";
import { Link } from "react-router-dom";
import QueryComponent from "../questionaire/QueryComponent";

const Questionaire = () => {
  const queriesAmount = Object.keys(queries).length;
  const [answered, setAnswered] = useState(0);
  const [correct, setCorrect] = useState(0);
  const resultRef = useRef(null);

  const scrollToBottom = () => {
    resultRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    if (queriesAmount === answered) {
    scrollToBottom() 
  }
  }, [answered, queriesAmount]);

  const onAnswerClick = (isCorrect) => {
    setAnswered(answered + 1);
    if (isCorrect) {
      setCorrect(correct + 1);
    }
  };

  const getFinalResult = () => {
    const mistakes = answered - correct;
    let text;
    if (mistakes === 0) {
      text = "איזה תותחים אתם! ריגשתם אותנו";
    } else if (mistakes > 0 && mistakes <= 0.3 * queriesAmount) {
      text = "כל הכבוד! אתם מכירים אותנו ממש טוב";
    } else if (mistakes > 0.3 * queriesAmount && mistakes <= 0.5 * queriesAmount) {
      text = "יפה! אתם מכירים אותנו טוב אבל יש פוטנציאל לשיפור";
    } else {
      text = "ניסיון יפה! צאו למקצה שיפורים";
    }
    return <div className="resultTextQuestionaire">{text}</div>;
  };

  const jsxQueries = queries.map((query, index) => (
    <QueryComponent onAnswerClick={onAnswerClick} query={query} />
  ));

  const finishedSection = () => {
    if (queriesAmount === answered) {
      return (
        <div className="questionaireResults" ref={resultRef}>
          {getFinalResult()}
          <Link className="resultScreenButton" to={"/"}>
            חזרה למסך הראשי
          </Link>
        </div>
      );
    }
  };

  return (
    <div className="queryPage">
      <h1 className="queryHeader">עד כמה אתם מכירים את הזוג</h1>
      <div className="questionaireQuestions">{jsxQueries}</div>
      {finishedSection()}
    </div>
  );
};
export default Questionaire;
